<template>
  <a-select
    :value="value"
    :show-search="true"
    :placeholder="placeholder"
    :filter-option="filterOption"
    allowClear
    style="width: 100%"
    @change="onChange"
  >
    <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id" :item="item">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { clientOption } from "@/api/option";

export default {
  props: ["value", "disabled", "placeholder", "warehouse"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataItems: [],
      queryLoading: false,
    };
  },
  methods: {
    filterOption(input, option) {
      const item = option.data.attrs.item;
      return (
        item.number.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
      );
    },
    onChange(value) {
      const index = this.dataItems.findIndex((item) => item.id == value);
      this.$emit("change", value);
      this.$emit("select", index === -1 ? {} : this.dataItems[index]);
    },
  },
  mounted() {
    this.queryLoading = true;
    clientOption({ page_size: 999999, warehouse: this.warehouse })
      .then((data) => (this.dataItems = data.results))
      .finally(() => (this.queryLoading = false));
  },
  watch: {
    warehouse: {
      handler(newValue, oldValue) {
        if (newValue) {
          clientOption({ page_size: 999999, warehouse: newValue }).then((data) => {
            this.dataItems = data.results;
            this.$emit("refreshClient");
          });
        }
      },
      // immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped></style>
